<template>
    <page-placeholder v-if="project.isLoaded">
        <v-row>
            <v-col :cols="12">
                <traffic-evolution-conversion/>
            </v-col>


            <v-col :xl="4" :lg="4" :md="12" :sm="6" :cols="12">
                <traffic-acquisition-channel :display-link-button="true"/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="12" :sm="6"  :cols="12">
                <advertising-performance :display-link-button="true"/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="12" :sm="12" :cols="12">
                <seo-visibility-presence-evolution :display-link-button="true"/>
            </v-col>


            <v-col :xl="4" :lg="4" :md="12" :sm="6" :cols="12">
                <market-segment-size/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="12" :sm="6" :cols="12">
                <market-segment-evolution :display-link-button="true"/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="12" :sm="6" :cols="12">
                <market-segment-progression-potential :display-link-button="true"/>
            </v-col>


            <v-col :xl="4" :lg="4" :md="12" :sm="6" :cols="12">
                <local-visibility-by-location :display-link-button="true"/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="12" :sm="6" :cols="12">
                <website-pages-best-pages :display-link-button="true"/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="12" :sm="6" :cols="12">
                <competition-top-competitor :display-link-button="true" :force-full-height="false"/>
            </v-col>


            <v-col :cols="12">
                <progression-axis-google-position-list/>
            </v-col>
        </v-row>
    </page-placeholder>
</template>

<script>
import PagePlaceholder from "@/components/yooda-components/PagePlaceholder";

import TrafficEvolutionConversion from "@/components/yooda-components/app/dashboard/TrafficEvolutionConversion";
import TrafficAcquisitionChannel from "@/components/yooda-components/app/dashboard/TrafficAcquisitionChannel";
import CompetitionTopCompetitor from "@/components/yooda-components/app/competition/CompetitionTopCompetitor";
import MarketSegmentEvolution from "@/components/yooda-components/app/dashboard/MarketSegmentEvolution";
import MarketSegmentProgressionPotential from "@/components/yooda-components/app/dashboard/MarketSegmentProgressionPotential";
import AdvertisingPerformance from "@/components/yooda-components/app/dashboard/AdvertisingPerformance";
import SeoVisibilityPresenceEvolution from "@/components/yooda-components/app/dashboard/SeoVisibilityPresenceEvolution";
import LocalVisibilityByLocation from "@/components/yooda-components/app/dashboard/LocalVisibilityByLocation";
import WebsitePagesBestPages from "@/components/yooda-components/app/dashboard/WebsitePagesBestPages";
import MarketSegmentSize from "@/components/yooda-components/app/dashboard/MarketSegmentSize";
import ProgressionAxisGooglePositionList from "@/components/yooda-components/app/progression-axis/ProgressionAxisGooglePositionList"


export default {
    components: {
        PagePlaceholder,
        TrafficEvolutionConversion,
        TrafficAcquisitionChannel,
        CompetitionTopCompetitor,
        MarketSegmentEvolution,
        MarketSegmentProgressionPotential,
        AdvertisingPerformance,
        SeoVisibilityPresenceEvolution,
        LocalVisibilityByLocation,
        WebsitePagesBestPages,
        MarketSegmentSize,
        ProgressionAxisGooglePositionList
    },

    beforeMount() {
        localStorage.setItem('defaultDashboardName','dashboard')
        this.setDefaultDashboardName('dashboard')
    }

}

</script>
